.navigation-boxes {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    background-color: #f0f0f0;
    /* Light grey background */
}

.navigation-boxes button {
    flex-grow: 1;
    padding: 10px;
    margin: 0 5px;
    background-color: #007bff;
    /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.navigation-boxes button:hover {
    background-color: #0056b3;
    /* Darker shade for hover effect */
}